import request from '@/utils/request'

// 查询图元管理列表
export function listPrimitiveManage (query) {
  return request({
    url: '/iot/primitiveManage/list',
    method: 'get',
    params: query
  })
}

// 查询图元管理详细
export function getPrimitiveManage (id) {
  return request({
    url: '/iot/primitiveManage/' + id,
    method: 'get'
  })
}

// 新增图元管理
export function addPrimitiveManage (data) {
  return request({
    url: '/iot/primitiveManage',
    method: 'post',
    data: data
  })
}

// 修改图元管理
export function updatePrimitiveManage (data) {
  return request({
    url: '/iot/primitiveManage',
    method: 'put',
    data: data
  })
}

// 删除图元管理
export function delPrimitiveManage (id) {
  return request({
    url: '/iot/primitiveManage/' + id,
    method: 'delete'
  })
}

// 导出图元管理
export function exportPrimitiveManage (query) {
  return request({
    url: '/iot/primitiveManage/export',
    method: 'get',
    params: query
  })
}
