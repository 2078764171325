<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="图元类型" prop="primitive">
        <a-select
          v-model="form.primitive"
          placeholder="请选择"
        >
          <a-select-option v-for="(d, index) in primitiveOptions" :key="index" :value="d.id">
            {{ d.typeName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="图元颜色" prop="primitiveColor" >
        <a-select placeholder="请选择图元颜色 " v-model="form.primitiveColor">
          <a-select-option v-for="(d, index) in colorOptions" :key="index" :value="Number(d.dictValue)" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属区划" prop="region" >
        <a-input v-model="form.region" placeholder="请输入所属区划" />
      </a-form-model-item>
      <a-form-model-item label="所属机构" prop="deptId">
        <a-tree-select
          v-model="form.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="是否删除" prop="status" >
        <a-select placeholder="请选择是否删除" v-model="form.status">
          <a-select-option v-for="(d, index) in statusOptions" :key="index" :value="Number(d.dictValue)" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPrimitiveManage, addPrimitiveManage, updatePrimitiveManage } from '@/api/iot/primitiveManage'
import { TreeSelect } from 'ant-design-vue'

export default {
  name: 'CreateForm',
  props: {
    deptOptions: {
      type: Array,
      required: true
    },
    colorOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    ATreeSelect: TreeSelect
  },
  data () {
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      // 图元类型选项
      primitiveOptions: [],
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        primitive: null,
        primitiveColor: null,
        region: null,
        deptId: null,
        status: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        primitive: [
          { required: true, message: '图元类型不能为空', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '所属区划不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '是否删除不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        primitive: null,
        primitiveColor: null,
        region: null,
        deptId: null,
        status: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      getPrimitiveManage(0).then(response => {
        this.primitiveOptions = response.primitives
      })
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPrimitiveManage(id).then(response => {
        this.form = response.data
        this.primitiveOptions = response.primitives
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePrimitiveManage(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addPrimitiveManage(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
